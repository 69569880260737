exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-us-js": () => import("./../../../src/pages/contact-us.js" /* webpackChunkName: "component---src-pages-contact-us-js" */),
  "component---src-pages-inbound-js": () => import("./../../../src/pages/inbound.js" /* webpackChunkName: "component---src-pages-inbound-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-operation-js": () => import("./../../../src/pages/operation.js" /* webpackChunkName: "component---src-pages-operation-js" */),
  "component---src-pages-outbound-js": () => import("./../../../src/pages/outbound.js" /* webpackChunkName: "component---src-pages-outbound-js" */),
  "component---src-pages-reports-js": () => import("./../../../src/pages/reports.js" /* webpackChunkName: "component---src-pages-reports-js" */),
  "component---src-pages-thankyou-js": () => import("./../../../src/pages/thankyou.js" /* webpackChunkName: "component---src-pages-thankyou-js" */),
  "component---src-templates-blog-author-js": () => import("./../../../src/templates/blog-author.js" /* webpackChunkName: "component---src-templates-blog-author-js" */),
  "component---src-templates-blog-categories-js": () => import("./../../../src/templates/blog-categories.js" /* webpackChunkName: "component---src-templates-blog-categories-js" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-choose-the-right-warehouse-management-system-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/choose-the-right-warehouse-management-system/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-choose-the-right-warehouse-management-system-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-rfid-for-warehouse-management-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/guide-to-rfid-for-warehouse-management/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-guide-to-rfid-for-warehouse-management-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-how-rfid-improve-warehouse-operations-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/how-rfid-improve-warehouse-operations/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-how-rfid-improve-warehouse-operations-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-rfid-based-warehouse-management-solutions-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/rfid-based-warehouse-management-solutions/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-rfid-based-warehouse-management-solutions-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-rfid-vs-barcode-for-warehouse-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/rfid-vs-barcode-for-warehouse/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-rfid-vs-barcode-for-warehouse-index-mdx" */),
  "component---src-templates-blog-single-js-content-file-path-content-blog-warehouse-optimization-tips-index-mdx": () => import("./../../../src/templates/blog-single.js?__contentFilePath=/opt/atlassian/pipelines/agent/build/content/blog/warehouse-optimization-tips/index.mdx" /* webpackChunkName: "component---src-templates-blog-single-js-content-file-path-content-blog-warehouse-optimization-tips-index-mdx" */)
}

